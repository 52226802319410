import React from 'react';
import {useSelector} from "react-redux";
import {chatById} from "../../redux/selectors/chats";
import {getProfile} from "../../redux/selectors/profile";
import Button from "../Button/Button";
import Div from "../Div/Div";

const SubscribeButton = (props:{id}) => {
    const {id} = props;
    const chat = useSelector(state=>chatById(state, id));
    const profile = useSelector(getProfile());
    return (
        <Div alignV={"center"}>
            {chat?.users?.find(x => x.id === profile?.id) ? <Button color={"red"} title={"Unsubscribe"}/> : <Button title={"Subscribe"}/>}
        </Div>
    );
};

export default SubscribeButton;