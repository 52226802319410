import React, {useEffect, useState} from 'react';
import Div from "../../components/Div/Div";
import {useNavigate, useParams} from "react-router";
import Avatar from "../../components/Avatar/Avatar";
import Space from "../../components/Space/Space";
import Text from "../../components/Text/Text";
import Button from "../../components/Button/Button";
import {base_files_url, conn} from "../../helpers/request/connect";
import {useDispatch, useSelector} from "react-redux";
import {chatsSetOne} from "../../redux/slices/chats";
import {chatById} from "../../redux/selectors/chats";
import {getProfile} from "../../redux/selectors/profile";
import {Link} from "react-router-dom";

const ChannelPage = () => {
    const params = useParams();
    const chat = useSelector(state=>chatById(state, params?.p2));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const profile = useSelector(getProfile());

    useEffect(()=>{
        conn("chat/getId", {id: params.p2}).then((res:any)=>{
            if(res.response){
                dispatch(chatsSetOne(res.response));
            }else{
                alert("Chat not found");
            }
        });
    }, [params.p2])


    return (
        <Div column loading={!chat} alignH={"center"}>
            <Avatar size={"lg"} src={chat?.image ? base_files_url + chat.image : ""} is_user/>
            <Space size={"md"} vertical/>
            <Text weight={"bold"}>{chat?.title}</Text>
            <Text>{chat?.is_private? "Private channel": "Free channel"}</Text>
            <Space size={"sm"} vertical/>
            <Div gap={12} alignV={"center"}>
            {chat?.users?.find(x => x.id === profile?.id) ? <>{chat?.users?.find(x => x.id === profile?.id)?.role === 0 ?<Button stretch={"fixed"} title={"Unsubscribe"}
                                                                       color={"red"} onClick={() => {
                conn("chat/unSubscribe", {id: params.p2}).then();
            }}/>: null}</>: <Button stretch={"fixed"} color={"green"} title={"Subscribe"} onClick={()=>{
                conn("chat/subscribe", {id: params.p2}).then();
            }}/>}
                <Button stretch={"fixed"} disabled={chat?.is_private} title={"Open channel"} onClick={()=>navigate("/chats/" + chat?.id)}/>
            </Div>
            {chat?.users?.length ? <><Space size={"md"} vertical/><Div gap={12}>

                {chat.users.map(user=>{
                    return <Div alignH={"center"} width={"none"} key={user.id} column gap={6}>
                        <Avatar size={"md"} src={user?.image ? base_files_url + user.image : ""} is_user/>
                        <Text align={"center"}>{user.fullname}</Text>
                    </Div>
                })}
            </Div></> : null}
        </Div>
    );
};

export default ChannelPage;