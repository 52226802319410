import React, {useEffect, useMemo, useRef, useState} from 'react';
import Input from "../../components/Input/Input";
import Div from "../../components/Div/Div";
import ListItem from "../../components/ListItem/ListItem";
import Avatar from "../../components/Avatar/Avatar";
import {userRole} from "../../helpers/users";
import Button from "../../components/Button/Button";
import {IoRemoveCircleSharp} from "react-icons/io5";
import Dialog from "../../components/Dialog/Dialog";
import {useDispatch, useSelector} from "react-redux";
import {chatById} from "../../redux/selectors/chats";
import {getProfile} from "../../redux/selectors/profile";
import {conn} from "../../helpers/request/connect";
import {usersSetMany} from "../../redux/slices/users";
import {usersAll} from "../../redux/selectors/users";
import Text from "../../components/Text/Text";
import "./GroupUserPicker.scss";
import ContextMenu from "../../components/ContextMenu/ContextMenu";

const GroupUserPicker = (props: {id, onClose}) => {
    const {id, onClose} = props;

    const chat = useSelector(state=>chatById(state, id));
    const profile = useSelector(getProfile());
    const users = useSelector(usersAll);
    const dispatch = useDispatch();
    const [align, setAlign] = useState(null);

    const [search, setSearch] = useState("");

    const searchUsers = useMemo(()=>{
        return users.filter(x => !chat?.users.find(y=> y.id === x.id))?.filter(x => x.fullname?.toLowerCase().includes(search.toLowerCase()));
    }, [search, users, chat?.users]);

    useEffect(()=>{
        conn("users/getFriends").then((res:any)=>{
           dispatch(usersSetMany(res.response));
        });
    }, []);

    return (
        <Dialog size={"xsm"} title={"Invite user"} onClose={onClose}>
            <Input clearButton={true} onFocus={(e)=>setAlign(e.target)} onClear={()=>setSearch("")} value={search} onChange={(e)=>setSearch(e)} stretch placeholder={"Search user"}/>
            {search?.length > 0 ? <div className={"group-user-search-result"}>
                {searchUsers.length > 0 && align ? <ContextMenu alignTo={align}  onClose={()=>setSearch("")} content={searchUsers.map(user=>{
                        return <ListItem hover onClick={()=>{
                            conn("chat/addToGroup", {user_id: user.id, chat_id: chat.id}).then(()=>setSearch(""));
                        }} key={user.id} title={user.fullname}/>
                    })}/> : <ContextMenu alignTo={align}  onClose={()=>setSearch("")} content={[<Text key={"nothing"}>Nothing found</Text>]}/>}
            </div>: null}
            <Div column>
                {chat?.users?.map(user=>{
                    return <ListItem key={user.id} title={user?.fullname} leftIcon={<Avatar size={"xsm"} title={user.fullname} src={user.image? "https://api.tport.site/files" + user.image : undefined}/>} rightIcon={userRole(profile?.id, chat.users) > 0 && userRole(user?.id, chat.users) < 1? <Button onClick={()=>{
                        conn("chat/removeFromGroup", {user_id: user.id, chat_id: chat.id}).then();
                    }} iconLeft={<IoRemoveCircleSharp color={"red"}/>} size={"xsm"} color={"transparent"}/>: undefined}/>
                })}
            </Div>
        </Dialog>
    );
};

export default GroupUserPicker;