import {useSelector} from "react-redux";
import {storiesAll} from "../../redux/selectors/stories";
import {getProfile} from "../../redux/selectors/profile";
import {useMemo} from "react";

export const useStory = (id)=>{
    const stories = useSelector(storiesAll);
    return stories?.filter(x => x?.user_id === id) ?? [];
}

export const useAllStories = ()=>{
    const profile = useSelector(getProfile());
    const stories = useSelector(storiesAll);

    const groupedStories = useMemo(()=>{
        const st = {};

        stories.forEach(val=>{
            if(!st[val?.user_id]){ st[val.user_id] = {user_id: val.user_id, stories: []} }
            st[val.user_id].stories.push(val);
        });

        return Object.values(st).sort((a: any, b: any) => {
            // 1. Сначала твои истории
            if (a.user_id === profile.id) return -1;
            if (b.user_id === profile.id) return 1;

            // 2. Если обе истории новые или обе старые, сортируем по дате
            if (a.stories[0].is_new === b.stories[0].is_new) {
                return b.stories[0].date - a.stories[0].date;
            }

            // 3. Новые истории идут первыми
            return a.stories[0].is_new ? -1 : 1;
        });
    }, [stories, profile.id]);

    return groupedStories;

}