import React from 'react';
import './App.scss';
import './i18n';
import {Provider} from "react-redux";
import Store from "./redux/stores/store";
import MainRouter from "./pages/MainRouter";

declare module 'i18next' {
    interface CustomTypeOptions {
        returnNull: false;
    }
}

function App() {
  return (
      <Provider store={Store}>
          <MainRouter/>
      </Provider>
  );
}

export default App;
