import {createEntityAdapter, createSlice, Slice} from "@reduxjs/toolkit";

export const usersAdapter = createEntityAdapter<any>({
    selectId: (user) => user.id,
});


const users: Slice = createSlice({
    name: "users",
    initialState: usersAdapter.getInitialState(),
    reducers: {
        usersSetAll: usersAdapter.setAll,
        usersSetMany: usersAdapter.setMany,
        usersSetOne: usersAdapter.setOne,
        usersUpdateOne: usersAdapter.updateOne,
        usersRemoveOne: usersAdapter.removeOne
    }
});

export const {usersSetAll, usersSetMany, usersSetOne, usersUpdateOne, usersRemoveOne} = users.actions;

export default users.reducer;
