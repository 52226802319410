import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Dialog from "../Dialog/Dialog";
import Div from "../Div/Div";
import {useDispatch, useSelector} from "react-redux";
import {messageById} from "../../redux/selectors/messages";
import Input from "../Input/Input";
import Button from "../Button/Button";
import {IoSend} from "react-icons/io5";
import Space from "../Space/Space";
import {conn} from "../../helpers/request/connect";
import messages, {editMessage} from "../../redux/slices/messages";
import Text from "../Text/Text";
import {unix} from "../../helpers";
import CommentItem from "./CommentItem";
import "./Comments.scss";

const Comments = (props: {message_id, onCLose}) => {

    const {message_id, onCLose} = props;
    const message = useSelector(state=>messageById(state, message_id));
    const [info, setInfo] = useState("");
    const dispatch = useDispatch();

    const sendComment = useCallback(()=>{
        const id = unix();
        const mss = {
            tmp_id: id,
            message: info,
            message_id: message.id,
            id: id,
        }
        dispatch(editMessage({
            id: message.id,
            changes: {
                comments: [...(message.comments ?? []), mss]
            }
        }))
        conn("comments/send", mss).then();
        setInfo("");
    }, [info, message]);

    useEffect(()=>{
        conn("comments/getComments", {message_id: message_id}).then((res: any)=>{
            dispatch(editMessage({
                id: message_id,
                changes: {comments: res.response}
            }));
        });
    }, [message_id]);

    return (<Dialog scroll={false} title={message?.message} onClose={onCLose}>
        <Div column className={"comments-content"}>
           <Div className={"comments-content-in"} column gap={6}>
               {message?.comments?.map(comment=>{
                   return <CommentItem key={comment.id} comment={comment}/>
               })}
           </Div>
        <Space vertical/>
        <Div>
            <Input onEnter={sendComment} stretch={true} value={info} onChange={(e)=>setInfo(e)} />
            <Space/>
            <Button disabled={info.length === 0} iconLeft={<IoSend/>} onMouseDown={(e)=>e.preventDefault()} onClick={sendComment}/>
        </Div>
        </Div>
        </Dialog>);
};

export default Comments;