import React, {useEffect, useState} from 'react';
import Div from "../../components/Div/Div";
import "./Header.scss";
import Space from "../../components/Space/Space";
import Input from "../../components/Input/Input";
import {CgSearch} from "react-icons/cg";
import Text from "../../components/Text/Text";
import HeaderProfile from "../HeaderProfile/HeaderProfile";
import {Link} from "react-router-dom";
import {useNavigate, useParams} from "react-router";
import {base_files_url, conn} from "../../helpers/request/connect";
import Skeleton from "../../components/Skeleton/Skeleton";
import Avatar from "../../components/Avatar/Avatar";
import Button from "../../components/Button/Button";
import {RiMenu2Fill} from "react-icons/ri";

let searchTimeout;
const Header = () => {
    const navigate = useNavigate();
    const params = useParams();


    const [opened, setOpened] = useState(null);
    const [opened2, setOpened2] = useState(null);

    const [search, setSearch] = useState("");
    const [searchFocus, setSearchFocus] = useState(null);
    const [searchResult, setSearchResult] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [searchHistory, setSearchHistory] = useState([]);
    const [menu, setMenu] = useState(null);



    const setHistoryToLocal = (item)=>{
        const history = JSON.parse(localStorage.getItem("search") ?? '[]');
        const index = history.indexOf(item);
        if(index > -1){
            history.splice(index,1);
        }
        history.unshift(item);
        localStorage.setItem("search", JSON.stringify(history.slice(0, 5)));
    }

    useEffect(()=>{
        if(params.p1 === "search") setSearch(params.p2);
    }, [params])

    useEffect(()=>{
        if(search.length > 0){
            setSearchLoading(true);
            clearTimeout(searchTimeout);
            searchTimeout = setTimeout(()=>{
                conn("users/search", {search: search}).then((res:any)=>{
                    setSearchResult(res.response);
                    setSearchLoading(false);
                });
            }, 1000);
        }else{
            setSearchResult([]);
            setSearchLoading(false);
        }
    }, [search]);

    return (
        <Div className={"header-full"} column={true}>
            <Space size={"sm"} vertical={true}/>
            <Div className="header">
                <Space size={"sm"}/>
                <Div className={"h-l"} alignH={"center"}>
                    <div className="logo">
                      <Link to={"/"}><Logo/></Link>
                    </div>
                </Div>
                <Div className={"h-c"} alignH={"center"}>
                    <Input
                        className={"main-search-input"}
                        stretch={true}
                        onEnter={(e)=>{
                            setHistoryToLocal(search);
                            navigate("/search/" + search);
                            e.target.blur();
                        }}
                        placeholder={"Search"}
                        leftIcon={<CgSearch/>}
                        clearButton={true}
                        onClear={()=> {
                            setSearch("");
                            setSearchFocus(false);
                            setTimeout(()=>setSearchFocus(true), 500);
                        }}
                        value={search}
                        onChange={(e)=>setSearch(e)}
                        onFocus={(e)=>{
                            const history = JSON.parse(localStorage.getItem("search") ?? '[]');
                            setSearchHistory(history);
                            setSearchFocus(e.target.closest(".input-content"));
                        }}
                        onFocusOut={()=> {
                           setTimeout(()=>setSearchFocus(null), 300);
                        }}
                    />
                    {(searchFocus && (searchHistory?.length || search?.length > 2)) && <Div column={true} className={"search-result"}>
                        {searchLoading && <Skeleton/> || <>
                        {search?.length > 0 && <>
                            {searchResult?.length && <>{searchResult.map(item=>{
                                return <Link key={item.id} to={item.type === "user" ? "/user/" + item.id : `/channel/${item.id}`} onClick={()=>{
                                    setHistoryToLocal(search);
                                    setSearchFocus(null);
                                }}>
                                    <Div gap={6}>
                                        <Avatar size={"xsm"} src={item.image? base_files_url + item.image: ""} is_user/>
                                        <Text singleLine={true}>{item.title}</Text>
                                    </Div>
                                    </Link>
                            })}
                               {/* {searchResult?.length > 4 && <Div alignV={"end"} onClick={() => {
                                    setSearchHistory([]);
                                    localStorage.removeItem("search");
                                }}><Link to={"/search/" + search}><Text size={"sm"} color={"main"}>Տեսնել բոլորը</Text></Link></Div> || <></>}*/}
                            </> || <Text align={"center"} color={"grey-light"}>Ոչին չի գտնվել</Text>}
                        </> || <>
                            {searchHistory?.length && <>{searchHistory.map((item, i)=>{
                                return <Div key={i} onClick={(e)=>{
                                    setSearch(item);
                                    setHistoryToLocal(item);
                                    setTimeout(()=>{
                                        setSearchFocus(document.querySelector(".main-search-input")?.closest(".input-container"));
                                    }, 500);

                                }}><Text singleLine={true}>{item}</Text></Div>
                            })}
                                <Div alignV={"end"} onClick={()=>{
                                    setSearchHistory([]);
                                    localStorage.removeItem("search");
                                }}><Text size={"sm"} color={"main"}>Clear list</Text></Div>
                            </>}
                        </>}
                        </>}
                    </Div>}
                </Div>
                {!searchFocus || document.body.clientWidth > 460? <Div className={"h-r"} alignH={"center"} alignV={"end"}>
                    <HeaderProfile/>
                </Div> : <></>}
                <Space size={"sm"}/>
                <Button className={"menu-button"} iconLeft={<RiMenu2Fill/>} onClick={()=>{
                    const body = document.body;
                    if(body.classList.contains("menu-opened")) body.classList.remove("menu-opened");
                    else body.classList.add("menu-opened");
                }}/>
            </Div>
            <Space size={"sm"} vertical={true}/>
        </Div>
    );
};

const Logo = ()=>{
    return <Div>
        <Text size={"md"} weight={"medium"} color={"main"}>Teleport</Text>

    </Div>
}

export default Header;
