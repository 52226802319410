import {createEntityAdapter, createSlice, Slice} from "@reduxjs/toolkit";

export const messAdapter = createEntityAdapter<any>({
    selectId: (item) => item.id,
    sortComparer: (a, b) => a.date - b.date,
});


const messages: Slice = createSlice({
    name: "messages",
    initialState: messAdapter.getInitialState(),
    reducers: {
        setAllMessages: messAdapter.setAll,
        setManyMessages: messAdapter.addMany,
        setMessage: messAdapter.setOne,
        editMessage: messAdapter.updateOne,
        removeMessage: messAdapter.removeOne,
    }
});

export const {setAllMessages, setManyMessages, setMessage, editMessage, removeMessage} = messages.actions;

export default messages.reducer;
