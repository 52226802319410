import React, {useEffect, useMemo, useState} from 'react';
import Text from "../../components/Text/Text";
import {getDateInterval, getZero} from "../../helpers";
import Tooltip from "../../components/Tooltip/Tooltip";
import {lang} from "../../helpers/lang/lang";

let timer: any;
const DateInterval = (props: {
    date: number
}) => {

    const {date} = props;
    const fullTime = useMemo(()=>{
        if(date){
            const dd = new Date(date * 1000);
            return getZero(dd.getDate()) + "." + getZero(dd.getMonth() + 1) + "." + dd.getFullYear() + " " + getZero(dd.getHours()) + ":" + getZero(dd.getMinutes());
        }
        return "";

    }, [date]);
    const [_date, setDate] = useState(fullTime);

    const func = ()=>{
        const dd = getDateInterval(Number(date));
        if(dd < 60) setDate("now");
        else if(dd < 60 * 59) setDate(Math.round(dd / 60) + " " + "m.");
        else if(dd < 60 * 59 * 24) setDate(Math.round(dd / 60 / 60) + " " + "h.");
        else if(dd < 60 * 60 * 24 * 6) setDate(Math.round(dd / 60 / 60 / 24) + " " + "d.");
        else {
            const m = new Date(date * 1000);
            setDate(`${getZero(m.getDate())}.${getZero(m.getMonth() + 1)}.${m.getFullYear()}`);
        }
    }

    useEffect(()=>{
        func();
        timer = setInterval(func, 1000);

        return ()=>clearInterval(timer);
    }, [date]);



    return (
        <div title={fullTime} style={{display: "inline-block"}}><Text singleLine size={"sm"} color={"grey-light"}>{_date}</Text></div>
    );
};

export default DateInterval;
