import Store from "../redux/stores/store";
import React from "react";
import users, {usersSetMany, usersSetOne} from "../redux/slices/users";
import {useSelector} from "react-redux";
import {usersAll} from "../redux/selectors/users";
import {conn} from "./request/connect";

export const UseUsers = ()=>{

     const users = Store.getState().users.entities;

    return users;
}

export const getUser = (id)=>{
    const user = Store.getState().users.entities[id];
    if(user){
        return user;
    }else{
        Store.dispatch(usersSetOne({id: id, loading: true, fullname: ""}));
        conn("users/getUser", {ids: id}).then((res:any)=>{
            if(res.response) Store.dispatch(usersSetMany(res.response));
        });
    }
}

export const userRole = (id, users)=>{
    return users.find(x=>Number(x.id) === Number(id))?.role;
}