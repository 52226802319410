import React, {useEffect, useState} from 'react';
import Div from "../../components/Div/Div";
import Button from "../../components/Button/Button";
import {BiAddToQueue, BiLogIn} from "react-icons/bi";
import "./HeaderProfile.scss";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getProfile} from "../../redux/selectors/profile";
import Avatar from "../../components/Avatar/Avatar";
import ContextMenu from "../../components/ContextMenu/ContextMenu";
import ListItem from "../../components/ListItem/ListItem";
import {FaUserAlt} from "react-icons/fa";
import {BsCardList, BsFillHeartFill} from "react-icons/bs";
import {cookies} from "../../helpers";
import {useNavigate} from "react-router";
import {setProfile} from "../../redux/slices/profile";
import Space from "../../components/Space/Space";
import Text from "../../components/Text/Text";
import {AiFillStar} from "react-icons/ai";
import {lang} from "../../helpers/lang/lang";
import {base_files_url} from "../../helpers/request/connect";
const HeaderProfile = () => {

    const profile = useSelector(getProfile());
    const [menu, setMenu] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <Div gap={12} className={"header-profile"} alignH={"center"} width={"none"}>
            {profile && <>
                <Div onClick={(e)=>setMenu(e.target.closest(".div"))}><Avatar is_user={true} src={profile.image? base_files_url + profile.image : ""}/></Div>
            </> || <><Link to={"/login"}><Button onClick={()=>{}} color={"transparent"} title={"Sign in"} iconRight={<BiLogIn/>}/></Link></>}
            {menu && <ContextMenu onClose={()=>setMenu(null)} alignTo={menu} content={[
                <><Div>
                    <Space size={"xsm"}/>
                    <Avatar is_user={true} src={profile.image? base_files_url + profile.image : ""}/>
                    <Space size={"xsm"}/>
                    <Div column={true} alignV={"center"}>
                    <Text weight={"bold"}>{profile.fullname}</Text>
                    <Text size={"sm"} color={"grey-light"}>{profile.email}</Text>
                    </Div>
                </Div>
                <Space size={"xsm"} vertical={true}/>
                </>,
                <Link to={"/profile"}><ListItem leftIcon={<FaUserAlt/>} title={"Profile"} hover={true} onClick={()=>setMenu(null)} /></Link>,
                <ListItem leftIcon={<BiLogIn/>} title={"Exit"} hover={true} onClick={()=> {
                    cookies.set("tp_id", "", -5).then(()=>{
                        cookies.set("tp_token", "", -5).then(()=>{
                            window.location.href = "/login"
                        });
                    });
                }} />,
            ]}/>}
        </Div>
    );
};

export default HeaderProfile;
