import React from 'react';
import "./Layout.scss";
import classNames from "classnames";
import ScrollBar from "../ScrollBar/ScrollBar";


const Layout = (props: {
    left?: JSX.Element;
    right?: JSX.Element,
    children?: JSX.Element | JSX.Element[];
    noBorder?:boolean
    wrapReverse?:boolean
}) => {
    const {left, right, children,noBorder,wrapReverse} = props;

    const classes = classNames("layout", {
        "no-border":noBorder,
        "wrapReverse":wrapReverse



    })

    return (
        <div className={classes}>
            {left && <div className="left-panel" >{left}</div> || null}
            <div className="page-content">{children ?? null}</div>
            {right && <div className="r-panel shadow-center" >{right}</div> || null}
        </div>
    );
};

export default Layout;
