export const formatTimeMinutes = (sec)=> {
    const minutes = Math.floor(sec / 60);
    const seconds = sec % 60;

    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
}
/*
const localAudio: any = document.getElementById('localAudio');
const remoteAudio: any = document.getElementById('remoteAudio');


const servers = {
    iceServers: [
        { urls: 'stun:stun.l.google.com:19302' } // STUN сервер Google
    ]
};

export const callToUser = async function(peerConnection) {
    // Захват аудио с микрофона
    const localStream = await navigator.mediaDevices.getUserMedia({ audio: true });
    localAudio.srcObject = localStream;

    // Добавление треков в соединение
    localStream.getTracks().forEach(track => peerConnection.addTrack(track, localStream));

    // Обработка ICE-кандидатов
    peerConnection.onicecandidate = (event) => {
        if (event.candidate) {
            signalingSocket.send(JSON.stringify({ type: 'candidate', candidate: event.candidate }));
        }
    };

    // Обработка поступающих медиа-треков
    peerConnection.ontrack = (event) => {
        remoteAudio.srcObject = event.streams[0];
    };

    // Создание предложения (offer)
    const offer = await peerConnection.createOffer();
    await peerConnection.setLocalDescription(offer);

    // Отправка предложения через сигналинг-сервер
    signalingSocket.send(JSON.stringify({ type: 'offer', offer: peerConnection.localDescription }));
}


export const callAnswer = async function (peerConnection, signalingSocket, offer) {
    // Захват аудио с микрофона
    const localStream = await navigator.mediaDevices.getUserMedia({ audio: true });
    localAudio.srcObject = localStream;

    // Добавление треков в соединение
    localStream.getTracks().forEach(track => peerConnection.addTrack(track, localStream));

    // Обработка ICE-кандидатов
    peerConnection.onicecandidate = (event) => {
        if (event.candidate) {
            signalingSocket.send(JSON.stringify({ type: 'candidate', candidate: event.candidate }));
        }
    };

    // Обработка поступающих медиа-треков
    peerConnection.ontrack = (event) => {
        remoteAudio.srcObject = event.streams[0];
    };

    // Установка удалённого описания (предложения)
    await peerConnection.setRemoteDescription(new RTCSessionDescription(offer));

    // Создание ответа (answer)
    const answer = await peerConnection.createAnswer();
    await peerConnection.setLocalDescription(answer);

    // Отправка ответа через сигналинг-сервер
    signalingSocket.send(JSON.stringify({ type: 'answer', answer: peerConnection.localDescription }));
}
*/
