import React, {useMemo} from 'react';
import classNames from "classnames";
import "./Avatar.scss";
import Text from "../../components/Text/Text";
import {FaUserAlt} from "react-icons/fa";
import {base_url} from "../../helpers/request/connect";

const Avatar = (props: {
    title?: string,
    is_circle?: boolean,
    src?: string,
    size?: "xsm" | "sm" | "md" | "lg",
    icon?: JSX.Element,
    is_user?: boolean
}) => {
    const {title, src, size = "sm", icon, is_user, is_circle} = props;

    const classes = classNames("avatar", {
        [`size-${size}`]: true,
        "circle": is_circle,
    });

    const image = useMemo(()=>{
        if (src) return <img src={src}></img>;
        else if(icon) return icon;
        else if(is_user) return <FaUserAlt/>
        return <Text weight={"bold"}>{title?.charAt(0)}</Text>
    }, [title, src, icon]);

    return (
        <div className={classes}>{image}</div>
    );
};

export default Avatar;
