import {createEntityAdapter, createSlice, Slice} from "@reduxjs/toolkit";

export const storiesAdapter = createEntityAdapter<any>({
    selectId: (story) => story?.id,
    sortComparer: (a, b) => b.date - a.date
});


const stories: Slice = createSlice({
    name: "stories",
    initialState: storiesAdapter.getInitialState(),
    reducers: {
        storiesSetAll: storiesAdapter.setAll,
        storiesSetMany: storiesAdapter.setMany,
        storiesSetOne: storiesAdapter.setOne,
        storiesUpdateOne: storiesAdapter.updateOne,
        storiesRemoveOne: storiesAdapter.removeOne,
    }
});

export const {storiesSetAll, storiesSetMany, storiesSetOne, storiesUpdateOne, storiesRemoveOne} = stories.actions;

export default stories.reducer;
