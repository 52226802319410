import {Cookies} from "react-cookie";
import {base_url} from "./request/connect";

export const isValidEmail = (email:string)=> {
    return /\S+@\S+\.\S+/.test(email);
}

export const checkAuth = ()=>{
    const id = cookies.get("tp_id");
    const token = cookies.get("tp_token");
    const isAuth = token && id;
    if(isAuth){
        cookies.set("tp_id", cookies.get("tp_id"), 30);
        cookies.set("tp_token", cookies.get("tp_token"), 30);
        return {id: id, token: token};
    }
    return false;
}


export const getImage = (img?: string, withEmpty = true)=>{
    return  base_url + "/images/" +(img? img : "no-image.jpg");
}

export const getZero = (x: number)=> {
    return Number(x) < 10? "0" + x: x;
}

export const getDate = (date: number, show_time?: boolean)=>{
    const d = new Date(date * 1000);
    return getZero(d.getDate()) + "/" + getZero(d.getMonth() + 1) + "/" + d.getFullYear() + (show_time? " " + getZero(d.getHours()) + ":" + getZero(d.getMinutes()): "");
}

export const getDateInterval = (date:number)=> {
    const now = unix(new Date().getTime());
    const difference = now - Number(date);

    return difference;
}

export const unix = (t?: number)=> Math.floor(Number(t ?? new Date().getTime()) / 1000);




const getExpires = (days: number)=>{
    const expires = new Date();
    expires.setTime(expires.getTime() + 3600 * 1000 * 24 * days);
    return expires;
}
const cooks = new Cookies();
export const cookies = {
    set: async (key: string, value: string, days?: number)=>{
        const options:any = {};
        if(days) options.expires = getExpires(days);
        cooks.set(key, value, options);
    },
    get: (key: string)=>{
        return cooks.get(key);
    }
}

export const scrollToLastMessage = (id, smooth = true, div?: string)=>{
    setTimeout(()=>{
        const block = (div? document.querySelector(div) : document)?.querySelector("*[data-id='"+id+"']");
        if(block) block.scrollIntoView(false);
    }, 1000);
}

export const rates = [
    {title: "AMD", id: "amd", symbol: "֏"},
    {title: "USD", id: "usd", symbol: "$"},
    {title: "RUB", id: "rub", symbol: "₽"},
]

export const getCategoriesID = (id, categories)=>{
    const cats = [];
    if(categories?.length) categories.forEach(cat=>{
          if(Number(id) === Number(cat.id)) cats.push(cat);
          else if(cat.items){
              cat.items?.forEach(cat2=>{
                  if(Number(id) === Number(cat2.id)) {
                      cats.push(cat);
                      cats.push(cat2);
                  }
                  else if(cat2.items){
                      cat2.items?.forEach(cat3=>{
                          if(Number(id) === Number(cat3.id)) {
                              cats.push(cat);
                              cats.push(cat2);
                              cats.push(cat3);
                          }
                      });
                  }
              })
          }
    });

    return cats;
}

export const isImageFile = (fileName) =>{
    // Массив допустимых расширений изображений
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg', 'tiff'];

    // Получение расширения файла
    const extension = fileName.split('.').pop().toLowerCase();

    // Проверка, содержится ли расширение в массиве допустимых расширений
    return imageExtensions.includes(extension);
}

export const isVideoFile = (fileName) => {
    // Массив допустимых расширений видеофайлов
    const videoExtensions = ['mp4', 'avi', 'mov', 'mkv', 'flv', 'wmv', 'webm', 'mpeg', 'mpg'];

    // Получение расширения файла
    const extension = fileName.split('.').pop().toLowerCase();

    // Проверка, содержится ли расширение в массиве допустимых расширений
    return videoExtensions.includes(extension);
}

export const myId = Number((()=>cookies.get("tp_id"))());
