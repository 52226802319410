import React, { useEffect, useMemo, useRef, useState } from 'react';
import Div from "../Div/Div";
import Button from "../Button/Button";
import { BsPauseFill, BsPlayFill } from "react-icons/bs";
import Text from "../Text/Text";
import { RiVoiceprintFill } from "react-icons/ri";
import {base_files_url} from "../../helpers/request/connect";

const VoicePlayer = (props: { file: { url: string } }) => {
    const { file } = props;
    const [state, setState] = useState("stop");
    const url = `${base_files_url}${file.url}`;
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    const audio = useRef<HTMLAudioElement>(null);


    const time = useMemo(() => {
        return duration ? Math.ceil(duration - currentTime) : "0";
    }, [duration, currentTime, file]);

    const handlePlayPause = () => {
        if (state === "play") {
            audio.current?.pause();
            setState("stop");
        } else {
            document.querySelectorAll("audio")?.forEach(val => (val as HTMLAudioElement).pause());
            audio.current?.play();
            setState("play");
        }
    };

    const handleLoadedMetadata = () => {
        if (audio.current) {
            setDuration(audio.current.duration);
        }
    };

    const handleTimeUpdate = () => {
        if (audio.current) {
            setCurrentTime(audio.current.currentTime);
        }
    };

    const handlePause = () => {
        setState("stop");
    };

    const handleEnded = () => {
        setCurrentTime(0);
        setState("stop");
    };

    return (
        <Div gap={6} alignH={"center"}>
            <Button
                onClick={handlePlayPause}
                iconLeft={state === "stop" ? <BsPlayFill /> : <BsPauseFill />}
            />
            <RiVoiceprintFill size={36} color={state === "play" ? "#0575F3" : "grey"} />
            <Text>{time}s.</Text>
            <audio
                onLoadedMetadata={handleLoadedMetadata}
                onTimeUpdate={handleTimeUpdate}
                onPause={handlePause}
                onEnded={handleEnded}
                onPlay={() => setState("play")}
                preload="auto"
                className="hidden-file-input"
                ref={audio}
                src={url}
            />
        </Div>
    );
};

export default VoicePlayer;
