import {combineReducers, configureStore} from '@reduxjs/toolkit';
import chats from "../slices/chats";
import profile from "../slices/profile";
import messages from "../slices/messages";
import users from "../slices/users";
import call from "../slices/call";
import stories from "../slices/stories";
const reducer = combineReducers({
    chats,
    messages,
    profile,
    users,
    call,
    stories
});

const configurator = () => {
    return configureStore({
        devTools: {name: "teleport"},
        reducer,
        middleware: getDefaultMiddleware => getDefaultMiddleware({
            serializableCheck: false
        })
    })
}

export type RootState = ReturnType<typeof reducer>;
export type Store = ReturnType<typeof configurator>;
export type Dispatch = Store['dispatch'];
const Store = configurator();

export default Store;
