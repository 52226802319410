import React, {useEffect, useRef, useState} from 'react';
import {Portal} from "react-portal";
import Div from "../../components/Div/Div";
import "./CallItem.scss";
import {useDispatch, useSelector} from "react-redux";
import {getCall} from "../../redux/selectors/call";
import {chatById} from "../../redux/selectors/chats";
import Avatar from "../../components/Avatar/Avatar";
import Text from "../../components/Text/Text";
import Button from "../../components/Button/Button";
import {setCall} from "../../redux/slices/call";
import {unix} from "../../helpers";
import {conn} from "../../helpers/request/connect";
import {formatTimeMinutes} from "../../helpers/call";
const audioCalling = require("../../assets/audio/phone-calling.mp3");
const audioRinging = require("../../assets/audio/ringing.mp3");
const audioBusy = require("../../assets/audio/busy.mp3");

const CallItem = () => {
    const call = useSelector(getCall());
    const chat = useSelector(state=>chatById(state, call?.chat_id));
    const dispatch = useDispatch();

    const [timer, setTimer] = useState("00:00");
    const [timerTimeOut, setTimerTimeOut] = useState(null);
    const [noAnswerTimer, setNoAnswerTimer] = useState<any>(null);





    useEffect(()=>{
        if(call?.call_type === "outcome" && !call?.started) setNoAnswerTimer(setTimeout(()=>{
            conn("calls/noAnswer", {id: call.id});
        }, 1000 * 32));

        return ()=>{
            clearTimeout(noAnswerTimer);
        }
    }, [call]);

    useEffect(()=>{
     if(call?.started){
         setTimerTimeOut(setInterval(()=>{
            setTimer(formatTimeMinutes(unix(new Date().getTime()) - call.started));
         }, 1000));
     }
        return ()=>clearInterval(timerTimeOut);
    }, [call?.started]);


    return (
        <Portal node={document && document.getElementById('call-content')}>
            <Div className={"call-dialog"} alignH={"center"} width={"none"}>
                {chat? <Div alignH={"center"} gap={6} className={"call-chat"}>
                    <Avatar is_user={true} src={chat?.image? "https://api.tport.site/files" + chat.image : ""}/>
                    <Text color={"light"} weight={"bold"}>{chat?.title}</Text>
                </Div>: null}
                <Div className={"call-status"} alignH={"center"}>
                    {call?.call_type === "outcome" && <Div alignH={"center"} gap={6}>
                        <Text color={"light"}>Calling...</Text>
                        <Button stretch={"fixed"} title={"End"} color={"red"} onClick={()=> {
                            dispatch(setCall(false));
                            conn("calls/endCall", {id: call?.id}).then();
                        }}/>
                        <CallAudio src={audioCalling} infinity={true}/>
                    </Div> || call?.started && <Div alignH={"center"} gap={6}>
                        <Text color={"light"}>{timer}</Text>
                        <Button stretch={"fixed"} title={"End"} color={"red"} onClick={()=> {
                            dispatch(setCall(false));
                            conn("calls/endCall", {id: call?.id, time: unix() - call?.started}).then();
                        }}/>
                    </Div> || call.call_type === "income" && <Div alignH={"center"} gap={6}>
                        <Text color={"light"}>Incoming</Text>
                        <Button stretch={"fixed"} title={"Accept"} color={"green"} onClick={()=> {
                            dispatch(setCall({...call, started: unix()}));
                            conn("calls/acceptCall", {id: call.id}).then();
                        }}/>
                        <Button stretch={"fixed"} title={"Reject"} color={"red"} onClick={()=> {
                            dispatch(setCall(false));
                            conn("calls/rejectCall", {id: call?.id}).then();
                        }}/>
                        <CallAudio src={audioRinging} infinity={true}/>
                    </Div> || call.call_type === "busy" && <Div gap={6} alignH={"center"}>
                        <Text singleLine color={"light"}>User is busy</Text>
                        <Button stretch={"fixed"} title={"Repeat call"} color={"green"} onClick={()=> {
                            dispatch(setCall({
                                chat_id: chat.id,
                                call_type: "outcome",
                            }));
                            conn("calls/callUser", {chat_id: chat?.id}).then((res:any)=>{
                                if(res.error === "busy"){
                                    dispatch(setCall({
                                        chat_id: chat?.id,
                                        type: "call",
                                        call_type: "busy"
                                    }));
                                }
                            });
                        }}/>
                        <Button stretch={"fixed"} title={"Cancel"} color={"red"} onClick={()=>dispatch(setCall(false))}/>
                        <CallAudio src={audioBusy} infinity={false}/>
                    </Div> || <></>}
                </Div>
            </Div>
        </Portal>
    );
};

export default CallItem;

const CallAudio = (props)=>{
    const {src, infinity} = props;


    const audioRef = useRef();

    useEffect(() => {
        const audioElement: any = audioRef.current;
        const handleEnded = () => {
            audioElement.currentTime = 0;
            audioElement.play();
        };

      if(infinity) audioElement.addEventListener('ended', handleEnded);

        return () => {
            audioElement.removeEventListener('ended', handleEnded);
        };
    }, [infinity]);

    return <audio ref={audioRef} autoPlay={true} src={src}/>;
}