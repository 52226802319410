import * as React from "react";
import "./Space.scss";
import classNames from "classnames";
 const Space = (props: {
     size?: "sm" | "xmd" | "md" | "lg" | "xlg" | "xsm" | "auto"|"xxl",
     vertical?: boolean,
     line?: boolean,
     className?:string
 })=>{
    const {vertical, size = "sm", line,className=""} = props;

    const classes = classNames("size-" + size, className, {
        space: true,
        vertical: vertical,
        line: line,


    });

    return (
        <div className={classes}  ></div>
    )
}
export default Space;