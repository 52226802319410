import {createSlice, Slice} from "@reduxjs/toolkit";



const profile: Slice = createSlice({
    name: "profile",
    initialState: false,
    reducers: {
        setProfile: (state, {payload}) => {
           return state = payload;
        },
    }
});

export const {setProfile} = profile.actions;

export default profile.reducer;