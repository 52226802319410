import {createEntityAdapter, createSlice, Slice} from "@reduxjs/toolkit";

export const chatsAdapter = createEntityAdapter<any>({
    selectId: (chat) => chat?.id,
    sortComparer: (a, b) => a.last_update - b.last_update,
});


const chats: Slice = createSlice({
    name: "chats",
    initialState: chatsAdapter.getInitialState(),
    reducers: {
        chatsSetAll: chatsAdapter.setAll,
        chatsSetMany: chatsAdapter.setMany,
        chatsSetOne: chatsAdapter.setOne,
        chatsUpdateOne: chatsAdapter.updateOne,
        chatsRemoveOne: chatsAdapter.removeOne
    }
});

export const {chatsSetAll, chatsSetMany, chatsSetOne, chatsUpdateOne, chatsRemoveOne} = chats.actions;

export default chats.reducer;
