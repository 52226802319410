import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Main from "./Main";
import ScrollBar from "../components/ScrollBar/ScrollBar";

const MainRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/:p1?/:p2?/:p3?/:p4?" element={<Main/>}/>
            </Routes>
        </BrowserRouter>
    );
};

export default MainRouter;
