import React, {useRef, useState} from 'react';
import {MdKeyboardVoice} from "react-icons/md";
import Button from "../Button/Button";
import {BsStopFill} from "react-icons/bs";
import {conn, uploadFile} from "../../helpers/request/connect";
import {myId} from "../../helpers";
import {setMessage} from "../../redux/slices/messages";
import {useDispatch} from "react-redux";

const VoiceButton = (props: {chat_id}) => {
    const {chat_id} = props;
    const [buttonState, setButtonState] = useState("default");
    const [isRecording, setIsRecording] = useState(false);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const streamRef = useRef(null);

    const dispatch = useDispatch();

    const startRecording = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        streamRef.current = stream;
        mediaRecorderRef.current = new MediaRecorder(stream);

        mediaRecorderRef.current.ondataavailable = event => {
            audioChunksRef.current.push(event.data);
        };

        mediaRecorderRef.current.onstop = () => {
            const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/mp3' });
            audioChunksRef.current = [];
            const audioFile = new File([audioBlob], 'voice.mp3', { type: 'audio/mp3' });
            sendAudio(audioFile);
            streamRef.current.getTracks().forEach(track => track.stop());
        };

        mediaRecorderRef.current.start();
        setIsRecording(true);
    };

    const stopRecording = () => {
        mediaRecorderRef.current.stop();
        setIsRecording(false);
    };

    const sendAudio = (audioBlob) => {
        uploadFile(audioBlob, "voice").then((res: any)=>{
            if(res.response){
                sendMessage({chat_id: chat_id,
                    message: "voice",
                    files: res.response});
            }
            setButtonState("default");
        });
    };


    const sendMessage = async(data) => {
        const date = Math.floor(Date.now() / 1000);

        const message = {
            id: date,
            message: "",
            chat_id: data.chat_id,
            date: date,
            user_id: myId,
            loading: true,
            tmp_id: date,
            files: [],
            type: "voice"
        }

        dispatch(setMessage(message));
        conn("message/send", {...message, files: data.files}).then(res=>{
        });
    };


    return (
        <Button loading={buttonState === "send"} color={buttonState === "rec"? "red" :(buttonState === "send"? "grey" :"main")} iconLeft={buttonState == "rec"? <BsStopFill/> :<MdKeyboardVoice/>} onClick={()=>{
            if(buttonState === "default"){
                setButtonState("rec");
                startRecording();
            }else if(buttonState === "rec"){
                setButtonState("send");
                stopRecording();
            }
        }}/>
    );
};

export default VoiceButton;