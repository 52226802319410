import React, {useState} from 'react';
import Div from "../../components/Div/Div";
import Space from "../../components/Space/Space";
import Text from "../../components/Text/Text";
import {lang} from "../../helpers/lang/lang";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";

const Register = () => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    return (
        <Div column alignH={"center"}>
            <Space size={"sm"} vertical={true}/>
            <Text size={"md"} weight={"medium"} align={"center"}>Registration</Text>
            <Space size={"xmd"} vertical={true}/>
            <Input
                label={"Email"}
                placeholder={"Email"}
                type={"email"}
                onChange={(e)=>setData(prev=>{return{...prev, email: e}})}
            />
            <Space size={"md"} vertical={true}/>
            <Button loading={loading} stretch={"fixed"} title={"Next"} onClick={()=>{
                console.log(data);
            }}/>
        </Div>
    );
};

export default Register;
