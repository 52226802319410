import axios from "axios";
import {Cookies} from "react-cookie";
import {cookies} from "../index";

export const base_url = "https://api.tport.site";
export const base_files_url = "https://api.tport.site/files/";

export const conn = (request: string, data: any = {}, config: any = {}, onProgress?: (e: { loaded: number, total: number })=>void)=>new Promise((resolve)=>{

    const headers: any = {
        lang: cookies.get("lang") ?? "en",
    }

    if(cookies.get("tp_token") && cookies.get("tp_id")) {
        headers.id = cookies.get("tp_id");
        headers.token = cookies.get("tp_token");
    }
    axios.post(`${base_url}/${request}`, {data}, {
        ...config,
        headers: headers,
        method: 'post',
        onUploadProgress: function(progressEvent:any) {
            if(onProgress) onProgress({loaded: progressEvent.loaded, total: progressEvent.total});
        }
    }).then(res=>{
        if(res && res.data) {
            resolve(res.data);
        };
    });
});

export const uploadFile = (file: any, type = "file", onProgress?: (e: { loaded: number, total: number })=>void)=>new Promise((resolve)=>{
    const cookies = new Cookies();
    const headers: any = {
        'Content-Type': 'multipart/form-data',
    }
    if(cookies.get("tp_token") && cookies.get("tp_id")) {
        headers.id = cookies.get("tp_id");
        headers.token = cookies.get("tp_token");
    }


    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("type", type);
    axios.post(base_url + '/files/upload', formData, {
        headers: headers,
        onUploadProgress: function(progressEvent:any) {
            if(onProgress) onProgress({loaded: progressEvent.loaded, total: progressEvent.total});
        }
    }).then((res:any)=>{
        if(res && res.data) resolve(res.data);
    });
});
