import React, {useCallback, useEffect, useMemo} from 'react';
import Div from "../Div/Div";
import {useSelector} from "react-redux";
import {chatsAll} from "../../redux/selectors/chats";
import ListItem from "../ListItem/ListItem";
import Avatar from "../Avatar/Avatar";
import Text from "../Text/Text";
import Space from "../Space/Space";
import {Link} from "react-router-dom";
import {useParams} from "react-router";
import {allMessages} from "../../redux/selectors/messages";
import Button from "../Button/Button";
import ScrollBar from "../ScrollBar/ScrollBar";
import {base_files_url} from "../../helpers/request/connect";
import message from "../Message/Message";
import {IoHeartSharp} from "react-icons/io5";

const Chats = () => {
    const chats = useSelector(chatsAll);
    const messages = useSelector(allMessages);
    const params = useParams();

    const getChatLastMessage = useCallback((id) => {
        return messages.filter(x => Number(x.chat_id) === Number(id)).sort((a, b) => b.date - a.date)?.[0] ?? {};
    }, [messages]);

    const chatsSorted = useMemo(() => {
        return [...chats].sort((a, b) => {
            return getChatLastMessage(b.id)?.date - getChatLastMessage(a.id)?.date
        });
    }, [getChatLastMessage, chats]);

    return (
        <ScrollBar><Div column={true} gap={2}>

            {chatsSorted?.map(chat => {
                return <Link onClick={()=>document.body.classList.remove("menu-opened")} to={`/chats/${chat?.id}`} key={chat?.id}><Div>
                    <ListItem
                        hover
                        selected={Number(params.p2) === Number(chat?.id) && params.p1 === "chats"}
                        title={<Div>
                            <Space/>
                            <Div column>
                                <Text weight={"medium"}>{chat?.title}</Text>
                                <Div alignH={"center"} gap={2}><Text color={"grey-light"} singleLine>{getChatLastMessage(chat?.id)?.message}</Text>{getChatLastMessage(chat?.id)?.liked? <IoHeartSharp color={"red"}/>: null}</Div>
                            </Div></Div>}
                        leftIcon={<Avatar src={chat?.image? base_files_url + chat.image : ""} is_user={true}/>}
                        rightIcon={chat?.count ? <Button size={"xsm"} title={chat?.count}/> : null}/>
                </Div></Link>
            })}
        </Div></ScrollBar>
    );
};

export default Chats;
