import React, {useEffect, useState} from 'react';
import Div from "../../components/Div/Div";
import {getUser, UseUsers} from "../../helpers/users";
import {useNavigate, useParams} from "react-router";
import Avatar from "../../components/Avatar/Avatar";
import Space from "../../components/Space/Space";
import Text from "../../components/Text/Text";
import Button from "../../components/Button/Button";
import {IoChatbubblesSharp} from "react-icons/io5";
import {base_files_url, conn} from "../../helpers/request/connect";
import Store from "../../redux/stores/store";
import {usersSetMany, usersSetOne} from "../../redux/slices/users";
import {useDispatch, useSelector} from "react-redux";
import {stat} from "fs";
import {userById} from "../../redux/selectors/users";

const UserPage = () => {
    const params = useParams();
    const user = useSelector(state=>userById(state, params?.p2));
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(()=>{
        conn("users/getUser", {ids: params.p2}).then((res:any)=>{
            if(res.response) dispatch(usersSetOne(res.response[0]));
        });
    }, [params.p2])

    useEffect(()=>{
        console.log("ddd", user);
    }, [user])

    return (
        <Div column loading={!user} alignH={"center"}>
            <Avatar size={"lg"} src={user?.image ? base_files_url + user.image : ""} is_user/>
            <Space size={"md"} vertical/>
            <Text weight={"bold"}>{user?.fullname}</Text>
            <Text>{user?.nickname}</Text>
            <Space size={"sm"} vertical/>
            <Button iconLeft={<IoChatbubblesSharp/>} title={"Send message"} onClick={()=>{
                conn("users/getUserChat", {id: params.p2}).then((res:any)=>{
                    if(res.response){
                        navigate("/chats/" + res.response);
                    }
                })
            }}/>
        </Div>
    );
};

export default UserPage;