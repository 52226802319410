import React from 'react';
import "./Skeleton.scss";

const Skeleton = () => {

    return (
        <div className={"skeleton"}>
            <div className="skeleton-1 skeleton-line"></div>
            <div className="skeleton-2 skeleton-line"></div>
            <div className="skeleton-3 skeleton-line"></div>
        </div>
    );
};

export default Skeleton;
