import {useDispatch, useSelector} from "react-redux";
import {userById, usersAll} from "../../redux/selectors/users";
import {useEffect} from "react";
import {usersSetMany, usersSetOne} from "../../redux/slices/users";
import {conn} from "../request/connect";

export const useUser = (id)=>{
    const user = useSelector(state=>userById(state, id));
    const dispatch = useDispatch();

    useEffect(()=>{
        if(!user){
            dispatch(usersSetOne({id: id, loading: true}));
            conn("users/getUser", {ids: id}).then((res:any)=>{
                if(res.response) dispatch(usersSetOne(res.response[0]));
            });
        }
    }, [id, user]);

    return user;
}
export const useManyUsers = (ids)=>{
    const users = useSelector(usersAll);
    const dispatch = useDispatch();

    useEffect(()=>{

        if(ids.length) {
            console.log("us", users, ids);
            const noLoadedUsers = ids.filter(y=>!users.find(x=>x.id === y));

            if (noLoadedUsers.length) {
                dispatch(usersSetMany(noLoadedUsers.map(x => {
                    return {id: x, loading: true}
                })));
                    conn("users/getUser", {ids: noLoadedUsers.map(x =>x)?.join(",")}).then((res: any) => {
                        if (res.response) dispatch(usersSetMany(res.response));
                    });
            }
        }
    }, [ids, users]);

    return users.filter(x => ids.includes(x.id));
}