import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {getProfile} from "../../redux/selectors/profile";
import Avatar from "../../components/Avatar/Avatar";
import {base_files_url, conn, uploadFile} from "../../helpers/request/connect";
import "./StoriesContent.scss";
import {FiPlus} from "react-icons/fi";
import Button from "../../components/Button/Button";
import {IoMdClose} from "react-icons/io";
import Uploader from "../../components/Uploader/Uploader";

const MyStory = () => {
    const profile = useSelector(getProfile());

    const [show, setShow] = useState(false);
    const [value, setValue] = useState(null);
    return (
        <><div className={"my-story"} onClick={()=>setShow(true)}>
            <Avatar size={"md"} is_user={true} src={profile.image? base_files_url + profile.image : ""}/>
            <div className={"my-story-button"}><FiPlus/></div>
        </div>
            {show? <div className={"story-show-bg"}>
                <Button className={"story-show-close"} hasHover onClick={()=>setShow(false)} color={"transparent"} iconLeft={<IoMdClose size={20}/>}/>
                <Uploader style={{
                    width: "300px",
                    height: "510px",
                    borderRadius: "20px"
                }} onChange={(e)=>setValue(e)}/>
                {value? <Button onClick={()=>{
                    uploadFile(value, "story").then((res:any)=>{
                        if(res.response){
                            conn("stories/create", {file: res.response}).then(()=>{
                                setShow(false);
                            });
                        }
                    })
                }}  title={"Add story"}/>: null}
            </div>: null}
        </>
    );
};

export default MyStory;