import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {messageById} from "../../redux/selectors/messages";
import Div from "../Div/Div";
import classNames from "classnames";
import {isImageFile, isVideoFile} from "../../helpers";
import "./Message.scss";
import DateInterval from "../../Items/DateInterval/DateInterval";
import Button from "../Button/Button";
import {SlOptions} from "react-icons/sl";
import ContextMenu from "../ContextMenu/ContextMenu";
import ListItem from "../ListItem/ListItem";
import {FiEdit, FiPhoneIncoming, FiPhoneMissed, FiPhoneOutgoing} from "react-icons/fi";
import {MdDeleteOutline, MdOutlineAttachFile} from "react-icons/md";
import Dialog from "../Dialog/Dialog";
import Text from "../Text/Text";
import {base_files_url, conn} from "../../helpers/request/connect";
import {editMessage, removeMessage} from "../../redux/slices/messages";
import {IoCheckmarkDoneOutline, IoHeartOutline, IoHeartSharp, IoPlay} from "react-icons/io5";
import {getProfile} from "../../redux/selectors/profile";
import {getUser} from "../../helpers/users";
import {userById, usersAll} from "../../redux/selectors/users";
import VoicePlayer from "../Voice/VoicePlayer";
import {chatById} from "../../redux/selectors/chats";
import {formatTimeMinutes} from "../../helpers/call";
import Input from "../Input/Input";
import {VscComment} from "react-icons/vsc";
import Comments from "../Comments/Comments";

const Message = (props:{id: string | number, prev?: number | string}) => {
    const {id, prev} = props;
    const message = useSelector(state=>messageById(state,id));
    const profile = useSelector(getProfile());
    const chat = useSelector(state=>chatById(state, message?.chat_id));
    const [mediaPreview, setMediaPreview] = useState(null);
    const user = useSelector(state => userById(state, message?.user_id));
    const [comments, setComments] = useState(false);


    const [context, setContext] = useState(null);
    const [menu, setMenu] = useState(null);
    const [editedMessage, setEditedMessage] = useState(message?.message ?? "");

    const dispatch = useDispatch();


    const messClasses = classNames({
        "message-item": true,
        // @ts-ignore
        "my": Number(profile.id) === Number(message.user_id),
        "loading": message.loading
    });

   /* useEffect(()=>{
        if((message?.type === "message" || !message?.type) && profile.id && message && !message.loading && !message.is_read && Number(profile.id) !== Number(message.user_id)){
             dispatch(editMessage({id: message.id, changes: {id_read: true}}));
            conn("message/read", {id: message.id}).then();
        }
    }, [message, profile])*/

    const files = useMemo(()=>{
        return {
            images: message?.files?.filter(x => (isImageFile(x.name) || isVideoFile(x.name)) && x.type === "media"),
            files: message?.files?.filter(x => x.type === "file"),
        }
    }, [message.files]);

const usersStore = useSelector(usersAll);
    const users = useCallback((id)=>{
        return getUser(id);
    }, [usersStore]);

    return (
        <>
            {message?.type === "call"? <Div className={"call-message"} width={"none"} alignH={"center"}>
                {message.message === "no_answer" || message.message === "missed_call" || message.message === "rejected_call" && <><Div className={`call-mess-icon ${callTexts[message.user_id === profile.id? "out": "in"]?.[message?.message]?.type}`}>
                    {callTexts[message.user_id === profile.id? "out": "in"]?.[message?.message]?.icon}
                </Div>
                    <Text size={"sm"} color={"grey-light"} singleLine className={"call-mess-text"}>
                        {user?.fullname}
                        {callTexts[message.user_id === profile.id? "out": "in"]?.[message?.message]?.title}
                    </Text></> || <>
                    <Div className={`call-mess-icon`}>
                        {message.user_id === profile.id? <FiPhoneOutgoing/> : <FiPhoneIncoming/>}
                    </Div>
                    <Text size={"sm"} color={"grey-light"} singleLine className={"call-mess-text"}>
                        {message.user_id === profile.id ? "Outgoing " : "Incoming "}
                        {formatTimeMinutes(Number(message?.message))}
                    </Text>
                </>}

            </Div> :<Div className={messClasses} column>
                {/* {prev !== message?.user_id && message.user_id !== profile.id ?<Div alignH={"center"} gap={2}>
                <Avatar is_user={true} size={"xsm"} src={users(message.user_id)?.image ?? ""}/>
                <Text padding={"xsm"} weight={"bold"}>{users(message.user_id)?.fullname}</Text></Div>: null}*/}
                <Text padding={"xsm"} linksView={true} className={"message-item-content"}>{message?.message}</Text>

                {files.images?.length ? <Div className={"message-images-content"}>
                    {files.images.map((file, i) => {
                        return <Div onClick={() => setMediaPreview(file)} key={i} className={"message-file-image"}>
                            {message?.loading ? <></> : <>{isImageFile(file.name) ?
                                <img src={`${base_files_url}${file.url}`}/> : <>
                                    <video preload={"metadata"} src={`${base_files_url}${file.url}`}></video>
                                    <IoPlay className={"play-icon"}/></>}</>}
                        </Div>
                    })}
                </Div> : null}
                {message.type === "voice" && !message.loading ? <>
                    <VoicePlayer file={message.files[0]}/>

                </> : <>
                    {files.files?.length ? <Div className={"message-images-content"}>
                        {files.files.map((file, i) => {
                            return <a key={i} href={`${base_files_url}${file.url}`} target={"_blank"}><Button
                                size={"xsm"} title={file.name} color={"transparent"} iconLeft={<MdOutlineAttachFile/>}/></a>
                        })}
                    </Div> : null}
                </>}

                {message.date ? <Div alignV={"end"} alignH={"center"} gap={6}>
                    <Div alignH={"center"}>
                        <Button onClick={()=>{
                        dispatch(editMessage({id: message.id, changes: {liked: !message.liked}}));
                        conn("message/likeMessage", {id: message.id}).then();
                    }} iconLeft={message.liked ?<IoHeartSharp color={"red"}/> : <IoHeartOutline />} color={"transparent"} size={"xsm"}/>
                        {message.total_likes > 0? <Text size={"sm"}>{message.total_likes}</Text>: null}
                        {chat?.type === "channel" || chat?.type === "group" ? <Div alignH={"center"}>
                            <Button iconLeft={<VscComment/>} onClick={()=>setComments(true)} size={"xsm"} color={"transparent"}/>
                            {message?.comments_count > 0? <Text size={"sm"}>{message.comments_count}</Text>: null}
                        </Div> : null}
                    </Div>
                    {message.edited? <><Text size={"sm"} color={"grey-light"}>Edited</Text></>: null}
                    <DateInterval date={message.date}/>
                    {Number(message?.user_id) === Number(profile?.id) && chat?.type === "private" ?
                        <IoCheckmarkDoneOutline color={message.is_read ? "green" : "grey"}/> : null}
                </Div> : null}
                <Button onClick={(e) => setContext(e.target.closest(".button"))} className={"message-menu-button"}
                        size={"xsm"} color={"light"} iconLeft={<SlOptions/>}/>
                {context && <ContextMenu content={[
                    message?.user_id === profile.id ?
                        <ListItem hover key={"edit"} onClick={() => {
                            setMenu("edit");
                            setContext(null);
                        }} title={"Edit"}
                                  leftIcon={<FiEdit/>}/> : <></>,
                    <ListItem key={"remove"} hover onClick={() => {
                        setContext(null);
                        setMenu("remove");
                    }} title={"Delete"} leftIcon={<MdDeleteOutline size={18} color={"red"}/>}/>
                ]} onClose={() => setContext(null)} alignTo={context}/>}

                {menu === "remove" && <Dialog
                    size={"xsm"}
                    title={"Remove message"}
                    onClose={() => setMenu(null)}
                    buttons={{
                        right: <Div gap={6}>
                            <Button title={"Cancel"} color={"transparent"} onClick={() => setMenu(null)}/>
                            <Button title={"Delete"} color={"red"} onClick={() => {
                                setMenu(null);
                                conn("message/remove", {id: message.id}).then();
                                dispatch(removeMessage(message.id));
                            }}/>
                        </Div>
                    }}
                >
                    <Text>Do you really want to delete the message?</Text>
                </Dialog> || menu === "edit" && <Dialog
                    size={"xsm"}
                    title={"Edit message"}
                    onClose={() => setMenu(null)}
                >
                    <Div gap={6} overflowHidden>
                        <Input value={editedMessage} onChange={(e)=>setEditedMessage(e)} />
                        <Button disabled={message?.message === editedMessage} title={"Edit"} color={"main"} onClick={() => {
                            setMenu(null);
                            conn("message/editMessage", {id: message.id, message: editedMessage}).then();
                            dispatch(editMessage({id: message.id, changes: {
                                    message: editedMessage
                                }}));
                        }}/>
                    </Div>
                </Dialog>}
                {mediaPreview ? <div className={"gallery-content"} onClick={(e: any) => {
                    if (!e.target.closest(".image") && !e.target.closest(".video")) setMediaPreview(null);
                }}>
                    {isImageFile(mediaPreview?.name) ?
                        <img className={"image"} src={`${base_files_url}${mediaPreview.url}`}/> :
                        <video className={"video"} autoPlay={true} controls preload={"auto"}
                               src={`${base_files_url}${mediaPreview.url}`}></video>}
                </div> : null}
            </Div>}
            {comments && <Comments message_id={message?.id} onCLose={()=>setComments(false)}/>}
        </>
    );
};

export default Message;

const callTexts = {
    in: {
        no_answer: {title: " No answer", icon: <FiPhoneIncoming/>},
        rejected_call: {title: " cancelled the call", icon: <FiPhoneIncoming/>, type: "alert"},
        missed_call: {title: " Missed call", icon: <FiPhoneMissed/>, type: "alert"},
    },
    out: {
        no_answer: {title: " No answer", icon: <FiPhoneOutgoing/>},
        rejected_call: {title: " cancelled the call", icon: <FiPhoneMissed/>, type: "alert"},
        missed_call: {title: " Missed call", icon: <FiPhoneMissed/>, type: "alert"},
    }
}