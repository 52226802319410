import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import "./StoriesContent.scss";
import {base_files_url, conn} from "../../helpers/request/connect";
import {BsChevronLeft, BsChevronRight} from "react-icons/bs";
import MyStory from "./MyStory";
import {useDispatch, useSelector} from "react-redux";
import {storiesRemoveOne, storiesSetAll, storiesUpdateOne} from "../../redux/slices/stories";
import {useAllStories, useStory} from "../../helpers/hooks/stories";
import {getProfile} from "../../redux/selectors/profile";
import {storyById} from "../../redux/selectors/stories";
import {userById} from "../../redux/selectors/users";
import Avatar from "../../components/Avatar/Avatar";
import Space from "../../components/Space/Space";
import Div from "../../components/Div/Div";
import DateInterval from "../DateInterval/DateInterval";
import Button from "../../components/Button/Button";
import {MdDeleteSweep} from "react-icons/md";
import {HiOutlineChevronLeft, HiOutlineChevronRight} from "react-icons/hi";
import {HiOutlineXMark} from "react-icons/hi2";
import Dialog from "../../components/Dialog/Dialog";
import {on} from "socket.io-client/build/esm-debug/on";
import {useManyUsers} from "../../helpers/hooks/users";
import {usersRemoveOne} from "../../redux/slices/users";

const StoriesContent = () => {

    const ref = useRef();
    const dispatch = useDispatch();
    const profile = useSelector(getProfile());

    const stories = useAllStories();

    const [selected, setSelected] = useState(null);

    useEffect(() => {
        conn("stories/getAllStories").then((res:any) => {
            dispatch(storiesSetAll(res.response));
        });
    }, []);

    useEffect(() => {
        // @ts-ignore
        const item: any = ref?.current;
        if(item){
           if(item.scrollWidth > item.clientWidth){
               item.closest(".stories-content").querySelector(".story-button.right").className.remove("hidden");
           }
        }
    }, [ref]);

    const scroll = (item, toRight = false) => {
        if (toRight) {
            item.scrollTo({left: item.scrollLeft + item.clientWidth * 0.8, behavior: "smooth",});
        }else{
            item.scrollTo({left: item.scrollLeft - item.clientWidth * 0.8, behavior: "smooth",});
        }
        setTimeout(() => {
            const leftButton = item.closest(".stories-content").querySelector(".story-button.left");
            const rightButton = item.closest(".stories-content").querySelector(".story-button.right");
            if (item.scrollLeft > 0) {
                leftButton.classList.remove("hidden");
            } else {
                leftButton.classList.add("hidden");
            }

            if (item.scrollLeft === item.scrollWidth - item.clientWidth) {
                rightButton.classList.add("hidden");
            } else {
                rightButton.classList.remove("hidden");
            }


        }, 300);
    }

    return (
        <>
            <div className={"stories-content"}>
                <div className={"story-button left hidden"} onClick={() => scroll(ref.current)}><BsChevronLeft/></div>
                <div className={"story-button right hidden"} onClick={() => scroll(ref.current, true)}><BsChevronRight/></div>
                <div className="story-content-in" ref={ref}>
                    <MyStory/>
                    {stories?.map((story:any, index)=>{
                        return <div onClick={()=>setSelected(story.stories[0].id)} className={`story-item${story.stories?.find(x => x.is_new) && story.user_id !== profile?.id ? " new": ""}`} key={story.user_id}>
                            <img src={`${base_files_url}/${story.stories[0].file}`}/>
                        </div>
                    })}
                </div>
            </div>
            {selected? <SelectedStoryShow selected={selected} onClose={()=>setSelected(null)}/>: null}
        </>
    );
};

export default StoriesContent;

const SelectedStoryShow = (props:{selected, onClose})=>{
    const {selected, onClose} = props;

    const stories = useAllStories();


    const [_selected, setSelected] = useState(selected);

    const itemN = useMemo(()=>{
       return stories?.findIndex((x:any)=>x.stories.findIndex(y => y.id === _selected) > -1);
    }, [stories, _selected]);

    const onEnd = useCallback((prev)=>{
        if(prev){
           if(itemN === 0) onClose();
           // @ts-ignore
           else setSelected(stories[(itemN -1)]?.stories?.[0]?.id)
        }else{
            // @ts-ignore
            if(itemN < stories.length - 1) setSelected(stories[(itemN +1)]?.stories?.[0]?.id);
            else onClose();
        }
    }, [itemN, stories]);

    return <div className={"story-view-content"}>
        <div className="story-close-button" onClick={onClose}><HiOutlineXMark /></div>
        <StoryItem id={_selected} onEnd={onEnd} />
    </div>
}
let int;
const StoryItem = (props: {id, onEnd})=>{
    const {id, onEnd} = props;
    const dispatch = useDispatch();
    const selectedStory = useSelector(state=>storyById(state, id));
    const stories = useStory(selectedStory?.user_id);
    const user = useSelector(state=>userById(state, selectedStory?.user_id));
    const profile = useSelector(getProfile());
    const [viewsContext, setViewsContext] = useState(false);

    const [selected, setSelected] = useState(stories.findIndex(x => x.id === id));


    useEffect(()=>{
       /* int = setTimeout(()=>{
            if(selected < stories.length - 1) {
                setSelected(prev => prev + 1);
            }
            else onEnd();
        }, 5000);*/

        return ()=>{
            clearTimeout(int);
        }
    }, [stories?.length,selected]);


    useEffect(()=>{
        setSelected(0);
    }, [id])

    useEffect(()=>{
        const selectedStory = stories[selected];
      if(stories[selected]?.user_id && stories[selected]?.user_id !== profile?.id)  conn("stories/view", {id: selectedStory?.id}).then(()=>{
          dispatch(storiesUpdateOne({id: selectedStory?.id, changes: {is_new: false}}))
      });
    }, [selected, profile, stories]);

    return <div className={"story-item-img"}>
        {selectedStory?.user_id === profile.id? <Button onClick={(e)=>{
            e.stopPropagation();
            conn("stories/remove", {id: selectedStory.id}).then();
            dispatch(storiesRemoveOne(selectedStory?.id));
            onEnd();

        }} color={"red"} iconLeft={<MdDeleteSweep/>} className={"story-remove-button"}/>: null}
          <div className="story-show-info-content">
          <div className="story-show-progress-content">
              {stories.map((val, index)=>{
                  return <div key={val.id} className={`story-progress-item${index === selected? " active": ""}`}></div>
              })}
          </div>
              <Space vertical/>
              <Div gap={6} alignH={"center"} className={"story-show-info-user"}>
                  <Avatar size={"xsm"} is_user={true} src={user?.image? base_files_url + user?.image : ""}/>
                  {user?.fullname}
                  <DateInterval date={stories[selected]?.date}/>
              </Div>

          </div>
        <img key={stories[selected]?.file} src={`${base_files_url}/${stories[selected]?.file}`}/>
        {stories[selected]?.user_id === profile?.id? <div onClick={()=>setViewsContext(true)} className={"story-view-button"}>See Story views</div>: null}
        <div className="story-control-buttons-content">
            <div onClick={()=>{
                if(selected === 0) onEnd(true);
                else setSelected(prev=>prev-1);
            }} className={"story-control-button left-button"}><HiOutlineChevronLeft /></div>
            <div onClick={()=>{
                if(selected < stories.length-1){
                    setSelected(prev=>prev+1);
                }else onEnd();
            }} className={"story-control-button right-button"}><HiOutlineChevronRight /></div>
        </div>
        {viewsContext? <StoriesViews onClose={()=>setViewsContext(false)} id={stories[selected].id}/>: null}
    </div>
}

const StoriesViews = (props:{id, onClose})=>{
    const {id, onClose} = props;
    const [views, setViews] = useState<any>([]);
    const dispatch = useDispatch();
    const users = useManyUsers(views?.map(x=>x.user_id));

    useEffect(()=>{
        conn("stories/getViews", {id: id}).then((res:any)=>{
            if(res.response) setViews(res.response);
        });
    }, []);

    return <Dialog title={"Story views"} onClose={onClose}>
        {views === "loading"? <>
            Loading...
        </>:(views?.length? <>
            {views.map(view=>{
                return <div key={view.id}>{users?.find(x=> x.id === view.user_id)?.fullname}</div>
            })}
        </>:<>
            No views
        </>)}
    </Dialog>
}