import * as React from 'react';
import classNames from 'classnames';
import './Text.scss';

const Text = (props: {
    children: any,
    size?: 'sm' | 'xmd' | 'md' | 'lm' | 'lg',
    weight?: 'bold' | 'medium',
    singleLine?: boolean,
    color?: 'light' | 'dark' | 'grey-light' | 'grey-middle' | 'main' | 'red' | 'grey',
    align?: 'center' | 'right',
    lineThrough?: boolean,
    padding?: 'xsm' | 'sm' | 'md',
    wrap?: 'pre' | 'unset',
    className?: string,
    linksView?: boolean,
}) => {
    const {
        children,
        size = '',
        weight,
        singleLine,
        color = 'dark',
        align,
        lineThrough,
        padding = 'default',
        wrap = 'unset',
        className = '',
        linksView = false,
    } = props;

    const textClass = classNames('text', className, size, color, align, {
        'm': weight === 'medium',
        'b': weight === 'bold',
        [`padding-${padding}`]: true,
        [`wrap-${wrap}`]: true,
        'line-through': lineThrough,
        'single-line': singleLine,
    });

    const linkify = (text: string) => {
        const urlPattern = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
        return text.split(urlPattern).map((part, index) => {
            if (urlPattern.test(part)) {
                const url = part.startsWith('http') ? part : `http://${part}`;
                return (
                    <a key={index} href={url} target="_blank" rel="noopener noreferrer">
                        {part}
                    </a>
                );
            }
            return part;
        });
    };

    const content = linksView && typeof children === 'string' ? linkify(children) : children;

    return <span className={textClass}>{content}</span>;
};

export default Text;
