import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getProfile} from "../../redux/selectors/profile";
import Div from "../../components/Div/Div";
import Avatar from "../../components/Avatar/Avatar";
import Space from "../../components/Space/Space";
import Text from "../../components/Text/Text";
import Uploader from "../../components/Uploader/Uploader";
import {base_files_url, conn, uploadFile} from "../../helpers/request/connect";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import {setProfile} from "../../redux/slices/profile";

const Profile = () => {
    const profile = useSelector(getProfile());
    const [edit, setEdit] = useState(false);
    const [info, setInfo] = useState(profile);
    const dispatch = useDispatch();
    const [image, setImage] = useState(null);

    useEffect(()=>{
        setInfo(profile);
    }, [profile])

    return (
        <Div column>
            <Space vertical size={"xsm"}/>
            <Div alignV={"end"}>
                {edit && <><Button title={"Cancel"} color={"transparent"} onClick={()=> {
                    setEdit(false);
                    setInfo(profile);
                    setImage(null);
                }}/>
                    <Button title={"Save"} stretch={"fixed"} color={"green"} onClick={()=> {
                        dispatch(setProfile(info));
                        setEdit(false);
                        if(image){
                            uploadFile(image, "avatar").then((res:any)=>{
                                conn("profile/edit", {
                                    ...info,
                                    image: res.response
                                });
                            })
                        }else{
                            conn("profile/edit", info);
                        }

                    }}/></> || <Button  onClick={() => setEdit(true)} stretch={"fixed"} color={"main"} title={"Edit profile"}/>}

            </Div>
            <Space vertical size={"sm"}/>
            <Div alignV={"center"}>
            {edit && <Uploader onChange={(e)=>setImage(e)} type={"avatar"} value={profile.image? base_files_url + profile.image: ""}/> || <Avatar is_user src={profile.image ? base_files_url + profile.image : ""} size={"lg"}/>}</Div>

            {edit && <>
                <Space vertical size={"sm"}/>
                <Text size={"sm"} color={"grey"}>Fullname</Text>
                <Space vertical size={"xsm"}/>
                <Input value={info?.fullname} autofocus onChange={(e)=>setInfo(prev=>{return{...prev, fullname: e}})}/>
                <Space vertical size={"sm"}/>
                <Text size={"sm"} color={"grey"}>Nickname</Text>
                <Space vertical size={"xsm"}/>
                <Input value={info?.nickname} autofocus onChange={(e)=>setInfo(prev=>{return{...prev, nickname: e}})}/>
                <Space vertical size={"sm"}/>
                <Text size={"sm"} color={"grey"}>Date of birth</Text>
                <Space vertical size={"xsm"}/>
                <Input type={"date"} value={info?.date_of_birth} autofocus onChange={(e)=>setInfo(prev=>{return{...prev, date_of_birth: e}})}/>
                <Space vertical size={"sm"}/>
                <Text size={"sm"} color={"grey"}>Phone</Text>
                <Space vertical size={"xsm"}/>
                <Input value={info?.phone} autofocus onChange={(e)=>setInfo(prev=>{return{...prev, phone: e}})}/>
            </> || <>
                <Space vertical size={"sm"}/>
                <Text size={"sm"} color={"grey"}>Fullname</Text>
                <Text weight={"bold"}>{profile?.fullname}</Text>
                <Space vertical size={"sm"}/>
                <Text size={"sm"} color={"grey"}>Nickname</Text>
                <Text weight={"bold"}>{profile?.nickname}</Text>
                <Space vertical size={"sm"}/>
                <Text size={"sm"} color={"grey"}>Date of birth</Text>
                <Text weight={"bold"}>{profile?.date_of_birth}</Text>
                <Space vertical size={"sm"}/>
                <Text size={"sm"} color={"grey"}>Phone</Text>
                <Text weight={"bold"}>{profile?.phone}</Text>
            </>}
            {edit && <>
                <Space vertical size={"md"}/>
                <Div gap={12} alignV={"center"}>

            </Div></>}
        </Div>
    );
};

export default Profile;