import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useParams} from "react-router";
import Div from "../Div/Div";
import Text from "../Text/Text";
import {useMessages} from "../../helpers/hooks/messages";
import Message from "../Message/Message";
import "./Chats.scss";
import Input from "../Input/Input";
import {IoCall, IoCloseOutline, IoFileTray, IoPlay, IoRemoveCircleSharp, IoSend, IoVideocam} from "react-icons/io5";
import Space from "../Space/Space";
import Button from "../Button/Button";
import input from "../Input/Input";

import {useDispatch, useSelector} from "react-redux";
import {setManyMessages, setMessage} from "../../redux/slices/messages";

import {base_files_url, conn, uploadFile} from "../../helpers/request/connect";
import {AiFillFileAdd} from "react-icons/ai";
import {isImageFile, isVideoFile, myId, unix} from "../../helpers";
import {CiFileOn} from "react-icons/ci";
import Skeleton from "../Skeleton/Skeleton";
import message from "../Message/Message";
import {chatsSetOne} from "../../redux/slices/chats";
import {userRole, UseUsers} from "../../helpers/users";
import VoiceButton from "../Voice/VoiceButton";
import Avatar from "../Avatar/Avatar";
import {chatById} from "../../redux/selectors/chats";
import {FaUserPlus, FaUsers} from "react-icons/fa";
import Dialog from "../Dialog/Dialog";
import ListItem from "../ListItem/ListItem";
import {getProfile} from "../../redux/selectors/profile";
import SubscribeButton from "../SubscribeButton/SubscribeButton";
import ContextMenu from "../ContextMenu/ContextMenu";
import {compressImage} from "../../helpers/media";
import GroupUserPicker from "../../Items/GroupUserPicker/GroupUserPicker";
import {getCall} from "../../redux/selectors/call";
import {setCall} from "../../redux/slices/call";

const ChatsContent = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const messages = useMessages(params.p2);
    const messagesScrollRef = useRef();
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [popup, setPopup] = useState(null);
    const profile = useSelector(getProfile());
    const [fileContext, setFileContext] = useState(null);
    const [selectFileType, setSelectFileType] = useState("media");
    const [input, setInput] = useState("");
    const [files, setFiles] = useState([]);
    const [allLoaded, setAllLoaded] = useState(false);
    const chat = useSelector(state=>chatById(state, params?.p2));

    const call = useSelector(getCall());


    useEffect(()=>{
        console.log("call",call);
    }, [call])



    useEffect(()=>{
        setPage(1);
      if(params.p2){
          setInput(localStorage.getItem("input_text_"+ params.p2) ?? "");
          conn("chat/getId", {id: params.p2}).then((res:any)=>{
              if(res.response){
                  dispatch(chatsSetOne(res.response));
              }else{
                  alert("Chat not found");
              }
          });
      }
    }, [params.p2]);

    useEffect(() => {
        setLoading(true);
        if(params.p2){
        conn("message/getMessages", {chat_id: params.p2, page: page}).then((res:any)=>{
          if(res.response) {
              dispatch(setManyMessages(res.response));
              if(res.response?.length < 30) setAllLoaded(true);
             setTimeout(()=>setLoading(false), 500);
          }

        });
    }
    }, [params.p2, page]);

    const sendMessage = async(data) => {
        const date = Math.floor(Date.now() / 1000);

        const message = {
              id: date,
              message: data.message,
              chat_id: data.chat_id,
              date: date,
              user_id: myId,
              loading: true,
              tmp_id: date,
              files: data.files?.map(x=>x.file),
          }
      dispatch(setMessage(message));
      setInput("");
      localStorage.removeItem("input_text_"+ params.p2);
      setFiles([]);
      const fileIds = [];
      if(data.files.length){

          for(const i in data.files){
          const fileItem = data.files[i];

              const file = isImageFile(fileItem.file.name) && fileItem.type === "media"? await compressImage(fileItem.file) :fileItem.file;
               // file.name = fileItem.file.name;
              const fRes: any = await uploadFile(file, fileItem.type);
              fileIds.push(fRes.response);
          }
      }

      conn("message/send", {...message, files: fileIds.join(",")}).then(res=>{

      });
    };


    useEffect(()=>{
        if(messages?.find(message =>(message?.type === "message" || !message?.type) && profile.id && message && !message.loading && !message.is_read && Number(profile.id) !== Number(message.user_id))){
            conn("message/read", {chat_id: chat?.id}).then();
        }
        // @ts-ignore
        messagesScrollRef?.current?.scrollIntoView({ behavior: page === 1? "auto" : "smooth" });
    }, [messages.length, page]);

    return (
        <Div className={"chat-content"} column>
            {params.p2 ? <Div column={true} className={"chat-content-in"}>
                <Div className={"chat-header"} alignH={"center"} gap={12}>
                    <Avatar is_user={true} src={chat?.image? base_files_url + chat?.image : ""}/>
                    <Text weight={"bold"}>{chat?.title}</Text>
                    {chat?.type === "group"?<Div className={"user-invite"} column alignH={"center"} alignV={"center"}
                          onClick={() => setPopup("invite")}>
                        <Button color={"transparent"} size={"xsm"} iconLeft={<FaUsers/>}/>
                    </Div> : (chat?.type === "private" ? <Div gap={6} className={"user-invite"}>
                        <Button size={"xsm"} iconLeft={<IoVideocam/>} color={"green"}/>
                        <Button disabled={call !== false} size={"xsm"} iconLeft={<IoCall/>} color={"green"} onClick={()=>{
                            const servers = {
                                iceServers: [
                                    { urls: 'stun:stun.l.google.com:19302' } // STUN сервер Google
                                ]
                            };
                            dispatch(setCall({
                                chat_id: chat?.id,
                                call_type: "outcome"
                            }));
                            conn("calls/callUser", {chat_id: chat?.id}).then((res:any)=>{
                                if(res.error === "busy"){
                                 dispatch(setCall({
                                     chat_id: chat?.id,
                                     call_type: "busy"
                                 }));
                                }else{
                                    dispatch(setCall({
                                        chat_id: chat?.id,
                                        call_type: "outcome",
                                        id: res.response
                                    }));
                                }
                            });
                        }}/>
                    </Div>: null)}
                </Div>
                <Space size={"xsm"} vertical/>
                <div className={"messages-scroll"} onScroll={(e)=>{

                    // @ts-ignore
                    if(e.target.scrollTop < 300 && !loading && !allLoaded){
                        setPage(prev=>{return prev + 1});
                    }
                }}>
                    <div className={"messages-scroll-in"}>
                        {/*@ts-ignore*/}
                        {loading && page > 1 ? <Skeleton/> : null}
                    {messages?.map((message, i) => {
                        return <Message prev={i > 0? messages[i - 1]?.user_id : 0} id={message.id} key={message.id}/>
                    })}
                        <div ref={messagesScrollRef}></div>
                </div>
                </div>
                <Space size={"xsm"} vertical/>
                {files?.filter(x => x.type === "media")?.length ? <><Div className={"files-content"}>
                {files.map((fileItem, i)=>{
                    if(fileItem.type === "media"){
                        const file = fileItem.file;
                        const src = URL.createObjectURL(file);
                        return <Div key={i} alignH={"center"} className={"file-item media-file"}>
                            {isImageFile(file.name)? <img src={src}/> : (isVideoFile(file.name)? <><video preload={"metadata"} src={src}></video><IoPlay className={"play-icon"}/></> : null)}
                            <Button className={"file-item-remove"} onClick={()=>{
                                setFiles(prev=>{return prev.filter((_, index) => index !== i)});
                            }} color={"transparent"} iconLeft={<IoCloseOutline color={"red"} />}/>
                        </Div>
                    }

                })}</Div><Space size={"xsm"} vertical/> </> : null}
                {files?.filter(x => x.type === "file")?.length ? <><Div className={"files-content"}>
                {files.map((fileItem, i)=>{
                    if(fileItem.type === "file"){
                        const file = fileItem.file;
                        return <Div key={i} alignH={"center"} className={"file-item"}>
                            <Div className={"file-item-icon"}>
                                <CiFileOn size={32}/>
                            </Div>
                            <Space size={"xsm"}/>
                            <Div column className={"file-item-text"}>
                                <Div className={"file-item-title"}>{file.name}</Div>
                                <Text color={"grey"}>{Math.ceil(file.size / 1024)}K</Text>
                            </Div>
                            <Button className={"file-item-remove"} onClick={()=>{
                                setFiles(prev=>{return prev.filter((_, index) => index !== i)});
                            }} color={"transparent"} iconLeft={<IoCloseOutline color={"red"} />}/>
                        </Div>
                    }

                })}</Div><Space size={"xsm"} vertical/> </> : null}
                {(chat?.type !== "channel" || chat?.users?.find(x => x.is_i)?.role > 0) ? <Div className={"chat-input"}>
                    <Button disabled={files.length > 9} iconLeft={<AiFillFileAdd/>}
                            onClick={(e)=>setFileContext(e.target.closest(".button"))}/>
                    {fileContext && <ContextMenu alignTo={fileContext} onClose={()=>setFileContext(null)} content={[
                        <ListItem key={"media"} hover onClick={() => {
                            document.getElementById("files-input")?.click();
                            setSelectFileType("media");
                            setFileContext(null);
                        }} title={"Add Media"}/>,
                        <ListItem key={"files"} hover title={"Add File"} onClick={() => {
                            document.getElementById("files-input")?.click();
                            setSelectFileType("file");
                            setFileContext(null);
                        }}/>,
                    ]} />}
                    <Space size={"xsm"}/>
                    <Input value={input} onChange={(e) => {
                        setInput(e);
                        localStorage.setItem("input_text_" + params.p2, e);
                    }} stretch placeholder={"Text"} onEnter={() => {
                        if(input.length || files.length){
                        sendMessage({
                            chat_id: params.p2,
                            message: input,
                            files: files
                        }).then();
                    }}}/>
                    <Space size={"xsm"}/>
                    <VoiceButton chat_id={params.p2}/>
                    <Space size={"xsm"}/>
                    <Button disabled={input.length === 0 && files.length === 0} iconLeft={<IoSend/>} onMouseDown={(e)=>e.preventDefault()} onClick={() => sendMessage({
                        chat_id: params.p2,
                        message: input,
                        files: files
                    })}/>
                    <input onChange={(e) => {
                        const nFiles = e.target.files;

                        if (nFiles.length + files.length > 10) {
                            alert("max 10 files");
                        } else {
                            const ff = Object.values(nFiles).map((x:any)=>{
                                return {file: x, type: selectFileType};
                            });
                            // @ts-ignore
                            setFiles(prev => {return [...prev, ...ff]});
                        }
                        e.target.value = null;
                    }} id={"files-input"} type={"file"} className={"hidden-file-input"} multiple/>
                </Div> : (chat?.type === "channel")? <SubscribeButton id={chat?.id}/>: null}
            </Div> : <Div alignH={"center"} alignV={"center"}>
                <Text color={"grey-light"}>Select the chat</Text>
            </Div>}
            {popup === "invite"? <GroupUserPicker id={chat.id} onClose={()=>setPopup(null)}/>: null}
        </Div>
    );
};

export default ChatsContent;
