import React, {useCallback} from 'react';
import Text from "../Text/Text";
import Div from "../Div/Div";
import {useSelector} from "react-redux";
import {userById, usersAll} from "../../redux/selectors/users";
import Avatar from "../Avatar/Avatar";
import "./Comments.scss";
import DateInterval from "../../Items/DateInterval/DateInterval";

const CommentItem = (props: {comment}) => {
    const {comment} = props;
    const user = useSelector(state=>userById(state, comment.user_id));

    return (
        <Div className={"comment-item"} gap={6} width={"none"}>
            <Avatar is_user src={user?.image? "https://api.tport.site/files/" + user.image: ""} size={"xsm"}/>
            <Div column gap={4}>
            <Div gap={4} column className={"comment-item-tx"}>
                <Text size={"sm"} weight={"medium"}>{user?.fullname}</Text>
                <Text>{comment?.message}</Text>
            </Div>
                <Div>
                    <DateInterval date={comment.date}/>
                </Div>

            </Div>
        </Div>
    );
};

export default CommentItem;