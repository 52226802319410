import {createSlice, Slice} from "@reduxjs/toolkit";



const call: Slice = createSlice({
    name: "call",
    initialState: false,
    reducers: {
        setCall: (state, {payload}) => {
           return state = payload;
        },
    }
});

export const {setCall} = call.actions;

export default call.reducer;